import React from "react"
import "./produtos-descricao.container.style.scss"
import horus from "../../images/Horus.svg"

export default class ProdutoDescricaoContainer extends React.Component{
    render(){
        return(
            <div className="produtos-descricao">
                <div className="produtos-descricao-content">
                    <div className="produtos-descricao-text">
                        <div className="produtos-descricao-title" style={{backgroundColor: this.props.page.color}}>
                            <div className="produtos-descricao-circulo">
                                <img className="produtos-descricao-logo" alt="Horus logo" src={horus}></img>
                            </div>
                            <h1 className="produtos-title-text">
                                Horus {this.props.page.nome}
                            </h1>
                        </div>
                        <p className="produtos-descricao-data">
                            {this.props.page.descricao}  
                        </p>
                    </div>
                    <img className="produtos-descricao-image" alt="Produtos descricao" src={this.props.page.link1}></img>
                </div>
            </div>
        )
    }
}