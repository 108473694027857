import React from "react"
import "./produtos-descricao.container.style.scss"
import ModulosCardComponent from "../../components/moduloscard/modulos_card.component"

export default class ProdutoCardContainer extends React.Component{
    render(){
        return(
            <div className="produtos-card">
                <div className="produtos-card-content">
                    <ModulosCardComponent info={this.props.info}></ModulosCardComponent>
                </div>
            </div>
        )
    }
}