import React from "react"
import "./botao-guia.component.style.scss"
import horus from "../../images/Horus.svg"

export default class BotaoGuiaComponent extends React.Component{
    
    comparePage(){
        let borda = {}
        if(this.props.index == this.props.currentPage)
            borda = {borderColor: this.props.color}
        return(borda)
    }

    render(){
        return(
            <div className="botao-guia-holder" style={this.comparePage()}>
                <div className="botao-guia" onClick={() => { this.props.movePage(this.props.index) }} style={{backgroundColor: this.props.color}}>
                    <img className="botao-guia-imagem" alt="Horus logo" src={horus}></img>
                </div>
            </div>
        )
    }
}