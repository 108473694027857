import React from "react"
import "./guia.container.style.scss"

import BotaoGuiaComponent from "../../components/botao-guia/botao-guia.component"
import passButton from "../../images/passButton.svg"
import passButtoninverted from "../../images/passButtoninverted.svg"

export default class GuiaContainer extends React.Component{

    generateGuia = (paginas) => {
        let retorno = []
        let index = 0
        paginas.forEach(pagina => {
            retorno.push(
                <BotaoGuiaComponent
                    color={pagina.color}
                    index={index}
                    currentPage={this.props.currentPage}
                    changePage={this.props.changePage}
                    movePage={this.props.movePage}
                >
                </BotaoGuiaComponent>
            )
            index++
        });
        return retorno
    }

    render(){
        return(
            <div className="guia">
                <div className="guia-content">
                    <img alt="Pass Button Back"className="guia-nom" onClick={() => { this.props.changePage(-1) }} src={passButtoninverted}></img>
                        {this.generateGuia(this.props.pages)}
                    <img alt="Pass Button"className="guia-nom" onClick={() => { this.props.changePage(1) }} src={passButton}></img>
                </div>
            </div>
        )
    }
}