import React from 'react'
import './modulos_card.components.style.scss'

const ModulosCardComponent = ({info}) => {
   let content = ((info.contentCard).split("\n"))
    return (
    <div className="espaco">
        <div className="computador-wrapper">
            <img className="computador" src={info.link2} />
        </div>
        <div className="card" style={{backgroundColor: info.color}}>
            <p className="nome-horus">Funcionalidades {info.nome}</p>
            <ul className="descricao-wrapper">
                {generateContent(content)}
            </ul>
        </div>
    </div>
    )
  }


const  generateContent = (content) =>{
    let contentList = []
    content.forEach(conteudo=>{
        contentList.push(
            <li  className="descricao">
                {conteudo}
            </li>
        )
    })
    return contentList
  }
export default ModulosCardComponent