import React from "react"

import DefaultLayout from "../layout/default.layout"
import GuiaContainer from "../containers/guia/guia.container"
import ProdutoDescricaoContainer from "../containers/produtos-descricao/produtos-descricao.container"
import ProdutoCardContainer from "../containers/produtos-card/produtos-descricao.container"

import Dashboard from "../images/Dashboard.svg"
import App from "../images/App.svg"
import Leituras from "../images/Leituras.svg"
import Formulas from "../images/Fórmulas.svg"
import Admin from "../images/Admin.svg"
import Dashboard2 from "../images/Dashboard2.png"
import App2 from "../images/App2.png"
import Leituras2 from "../images/Leituras2.png"
import Formulas2 from "../images/Fórmulas2.png"
import Admin2 from "../images/Admin2.png"

import { Helmet } from 'react-helmet'

export default class Modulos extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentPage: 0 }
  }

  changePage = (direction) => {
    let newPage = this.state.currentPage + parseInt(direction)
    console.log(newPage, this.state.currentPage, direction)
    if (newPage > 4)
      newPage = 0
    if (newPage < 0)
      newPage = 4
    this.setState({ currentPage: newPage })
  }

  movePage = (page) => {
    this.setState({ currentPage: page })
  }

  render = () => {
    let paginas = [
      {
        nome: "App",
        descricao: "Modernize a forma como as leituras são feitas e deixe de usar montantes de papéis acumulados. Com o aplicativo do Horus é possível realizar leituras de sensores em campo de forma rápida e prática através de tablets ou smartphones.",
        contentCard: "Realize leituras offline em campo utilizando tablets e smartphones;\n Tenha o envio automático das leituras do aplicativo para a plataforma web; \n Utilize um QR Code ou o nome do sensor  para realizar leituras;\n Disponha de um histórico de leituras do dispositivo;\n Para sua segurança, tenha backup dos dados de leituras no aplicativo;\n Rastreie as leituras realizadas, com dados do usuário, horário e sua localização utilizando o GPS do dispositivo;\n Tenha uma ferramenta prática e essencial de trabalho em campo, descartando o uso de extensas planilhas em folhas de papel.",
        color: "#248BA5",
        link1: App,
        link2: App2
      },
      {
        nome: "Leituras",
        descricao: "Gerencie as leituras e crie novos sensores para as estruturas da sua empresa, atendendo às demandas de operação com a flexibilidade do sistema Horus. Além disso, otimize o trabalho da sua equipe com uso de planilhas e gráficos online, deixando de lado as extensas planilhas em folhas de papel.", 
        contentCard: " Cadastre sensores, adicionando campos customizados a serem preenchidos; \n Visualize gráficos e planilhas das leituras realizadas;\n Adicione a localização e observações úteis em cada sensor cadastrado;\nAdicione e edite possíveis leituras incorretas;\nControle o horário, localização e usuário que realiza a leitura em campo;\nExporte e importe leituras de sensores em planilhas de excel;\nTenha a segurança dos seus dados com o armazenamento com backup em nuvem.",
        color: "#27AE60",
        link1: Leituras,
        link2: Leituras2
      },
      {
        nome: "Fórmulas",
        descricao: "Crie fórmulas e diferentes tipos de variáveis para cada sensor cadastrado, tendo a flexibilidade e autonomia nos cálculos de engenharia. Analise os dados da sua empresa criando níveis de alerta de sensores para agilizar a tomada de decisão sobre a segurança das estruturas da sua companhia.",
        contentCard: "Crie variáveis globais envolvendo os sensores da sua estrutura, como variáveis de clima, pluviometria, etc; \n Crie fórmulas e variáveis parciais para cada sensor individualmente; \n Tenha flexibilidade de criação e alteração de cálculos de engenharia; \n Crie níveis de alerta de sensores, agilizando a análise de segurança das estruturas da sua empresa.",
        color: "#D35400",
        link1: Formulas,
        link2: Formulas2
      },
      {
        nome: "Dashboard",
        descricao: "Tenha à disposição todos os dados importantes, de forma concisa e otimizada, para a gestão das suas  estruturas. Além de gráficos e planilhas de leituras, tenha acesso à mapas e seções instrumentadas em um dashboard totalmente personalizável.",
        contentCard: "Visualize gráficos e tabelas dos dados calculados;\nVisualize lista de sensores em alerta e outros dados como pluviometria da região;\n  Tenha acesso a um mapa de sensores e estruturas, destacando a localização dos sensores em alerta;\n  Crie seções instrumentadas com acesso aos gráficos e dados dos sensores vinculados à elas;\n  Disponha de um dashboard customizável independente por usuário, escolhendo os dados que deseja  representar na sua tela principal;\n Tenha autonomia na composição, análise e exportação de dados.",
        color: "#16A085",
        link1: Dashboard,
        link2: Dashboard2
      },
      {
        nome: "Admin",
        descricao: "Tenha autonomia de controle sobre seus usuários e seus acessos ao sistema Horus, minimizando os erros operacionais ao restringir as funcionalidades e módulos que cada um pode acessar. Além disso, tenha a agilidade de subdividir e criar quantas estruturas desejar, otimizando a análise dos dados da sua empresa.",
        contentCard: "Crie usuários com logins individuais;\n Tenha controle e gestão de acessos de cada usuário do sistema Horus, escolhendo os módulos que cada usuário pode acessar;\n  Cadastre emails que receberão alertas de sensores da empresa, agilizando a tomada de decisão na segurança das suas estruturas;\n Cadastre e gerencie com autonomia as estruturas da sua empresa. ",
        color: "#9B59B6",
        link1: Admin,
        link2: Admin2
      }
    ]

    return (
      <DefaultLayout>
        <Helmet>
          <title>Horus | Gerenciamento de Barragens</title>
        </Helmet>
        <GuiaContainer currentPage={this.state.currentPage} pages={paginas} movePage={this.movePage.bind(this)} changePage={this.changePage.bind(this)}></GuiaContainer>
        <ProdutoDescricaoContainer page={paginas[this.state.currentPage]}></ProdutoDescricaoContainer>
        <ProdutoCardContainer info={paginas[this.state.currentPage]}></ProdutoCardContainer>
      </DefaultLayout>
    )
  }
}
